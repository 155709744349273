import React from 'react';
import { useEffect, useRef, useState } from 'react';
import logo from "./o3-cartevisite-recto.svg"

import config from './config.json'
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return windowDimensions;
  }
  


const Carte = (props) => {
    const { height, width } = useWindowDimensions();
    console.log(config)
    if (width > height) {
        var classMain = "mainH"
        var colL = "col-9"
        var colR = "col-3"
    }
    else{
        var classMain = "mainV"
        var colL = "col-12"
        var colR = "col-12"
    }
    return (
        <div id="main" className= {classMain  }>
            <div className="container-fluid">
                <div className="row">
                    <div className={ colL }>
                        <div className='logo'>
                         <img src={logo} />
                        </div>
                        <div className='name'>{ config.name }</div>
                        <div className='phones'>
                            <div className='mobile'><span className='red'>Mob :</span> <a href={`tel://${config.mobile}`}>{ config.mobile_display }</a></div>
                            <div className='phone'><span className='red'> Tél :</span> <a href={`tel://${config.phone}`}>{ config.phone_display }</a></div>
                        </div>
                        <div className='mail_url'>
                            <div className='mail'><a href={`mailto: ${config.email}`}>{ config.email }</a></div>
                            <div className='url'><a href={ `https://${config.web_site }`} target="_blank">{ config.web_site }</a></div>
                        </div>
                        <div className='fulladresse'>
                          <div className='adresse'>{ config.adresse }</div>
                          <div>
                            <div className='cp'>{ config.cp }</div>
                            <div className='ville'>{ config.ville }</div>
                            <div className='pays'>{ config.pays }</div>
                          </div>
                        </div>
                        

                    </div>        
                    <div className={ colR }>
                        <div className='downloadContainer'>
                        <div><a href='./vcard.vcf' download><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z"/></svg></a></div>
                        <div className='downloadText'>Télécharger<br/>la Vcard</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Carte;