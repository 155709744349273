import {useState, useEffect, useRef} from "react"
import './App.css';
import Carte from "./Carte";

function App() {

  return (
    <div className="App">
     
      <Carte/>
     
    </div>
  );
}

export default App;
